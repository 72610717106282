var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"app"},[_c('app-bar',{attrs:{"hide-nav-icon":"","options":{ 'clipped-left': true, 'clipped-right': true }}}),_c('v-main',{staticClass:"pb-0 mb-0"},[_c('v-container',{staticClass:"fill-height"},[_c('v-card',{staticClass:"mx-auto",attrs:{"color":"rgba(255,255,255,0)","flat":"","max-width":"800"}},[_c('div',{staticClass:"d-flex flex-wrap flex-row justify-center"},[(
              _vm.$permission.$can('message.statistic', ['user', 'service']) ||
              _vm.$permission.$can('ticket.statistic', ['user', 'service'])
            )?_c('v-card',{staticClass:"mr-5 mb-12",attrs:{"to":{ name: 'statistic-message' },"flat":"","color":"rgba(255,255,255,0)","width":"130","height":"130"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":"1","color":hover ? 'secondary' : 'white',"width":"130","height":"130"}},[_c('div',{staticClass:"d-flex justify-center align-center fill-height"},[_c('v-icon',{attrs:{"color":hover ? 'white' : 'secondary',"size":"60"}},[_vm._v("mdi-chart-box-outline")])],1)])]}}],null,false,788949834)}),_c('div',{staticClass:"text-center mt-2"},[_c('span',{staticClass:"secondary-font font-weight-medium"},[_vm._v(_vm._s(_vm.$t("menu.statistics.title")))])])],1):_vm._e(),(_vm.$permission.$can('conversation.get', ['user', 'service']))?_c('v-card',{staticClass:"mr-5 mb-12",attrs:{"to":{ name: 'chat' },"flat":"","color":"rgba(255,255,255,0)","width":"130","height":"130"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":"1","color":hover ? 'secondary' : 'white',"width":"130","height":"130"}},[_c('div',{staticClass:"d-flex justify-center align-center fill-height"},[_c('v-icon',{attrs:{"color":hover ? 'white' : 'secondary',"size":"60"}},[_vm._v("mdi-chat-outline")])],1)])]}}],null,false,420669696)}),_c('div',{staticClass:"text-center mt-2"},[_c('span',{staticClass:"secondary-font font-weight-medium"},[_vm._v(_vm._s(_vm.$t("menu.conversation")))])])],1):_vm._e(),(_vm.$permission.$can('ticket.list', ['user', 'service']))?_c('v-card',{staticClass:"mr-5 mb-12",attrs:{"to":{ name: 'tickets' },"flat":"","color":"rgba(255,255,255,0)","width":"130","height":"130"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":"1","color":hover ? 'secondary' : 'white',"width":"130","height":"130"}},[_c('div',{staticClass:"d-flex justify-center align-center fill-height"},[_c('v-icon',{attrs:{"color":hover ? 'white' : 'secondary',"size":"60"}},[_vm._v("mdi-ticket-outline")])],1)])]}}],null,false,951914778)}),_c('div',{staticClass:"text-center mt-2"},[_c('span',{staticClass:"secondary-font font-weight-medium"},[_vm._v(_vm._s(_vm.$i18n.t("menu.tickets")))])])],1):_vm._e(),(_vm.$permission.$can('form.list', ['user', 'service']))?_c('v-card',{staticClass:"mr-5 mb-12",attrs:{"to":{ name: 'forms' },"flat":"","color":"rgba(255,255,255,0)","width":"130","height":"130"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":"1","color":hover ? 'secondary' : 'white',"width":"130","height":"130"}},[_c('div',{staticClass:"d-flex justify-center align-center fill-height"},[_c('v-icon',{attrs:{"color":hover ? 'white' : 'secondary',"size":"60"}},[_vm._v("mdi-form-select")])],1)])]}}],null,false,1986500672)}),_c('div',{staticClass:"text-center mt-2"},[_c('span',{staticClass:"secondary-font font-weight-medium"},[_vm._v(_vm._s(_vm.$i18n.t("menu.forms")))])])],1):_vm._e(),(_vm.$permission.$can('sla.get', ['user', 'service']))?_c('v-card',{staticClass:"mr-5 mb-12",attrs:{"to":{ name: 'sla' },"flat":"","color":"rgba(255,255,255,0)","width":"130","height":"130"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":"1","color":hover ? 'secondary' : 'white',"width":"130","height":"130"}},[_c('div',{staticClass:"d-flex justify-center align-center fill-height"},[_c('v-icon',{attrs:{"color":hover ? 'white' : 'secondary',"size":"60"}},[_vm._v("mdi-handshake-outline")])],1)])]}}],null,false,856174473)}),_c('div',{staticClass:"text-center mt-2"},[_c('span',{staticClass:"secondary-font font-weight-medium"},[_vm._v(_vm._s(_vm.$i18n.t("menu.sla")))])])],1):_vm._e(),(
              _vm.$permission.$can('service.provision.get', ['user', 'service'])
            )?_c('v-card',{staticClass:"mr-5 mb-12",attrs:{"to":{ name: 'provision' },"flat":"","color":"rgba(255,255,255,0)","width":"130","height":"130"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":"1","color":hover ? 'secondary' : 'white',"width":"130","height":"130"}},[_c('div',{staticClass:"d-flex justify-center align-center fill-height"},[_c('v-icon',{attrs:{"color":hover ? 'white' : 'secondary',"size":"60"}},[_vm._v("mdi-wallet-outline")])],1)])]}}],null,false,3937595001)}),_c('div',{staticClass:"text-center mt-2"},[_c('span',{staticClass:"secondary-font font-weight-medium"},[_vm._v(_vm._s(_vm.$i18n.t("menu.provision")))])])],1):_vm._e(),(_vm.$permission.$can('service.get', ['user', 'service']))?_c('v-card',{staticClass:"mr-5 mb-12",attrs:{"to":{ name: 'service' },"flat":"","color":"rgba(255,255,255,0)","width":"130","height":"130"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":"1","color":hover ? 'secondary' : 'white',"width":"130","height":"130"}},[_c('div',{staticClass:"d-flex justify-center align-center fill-height"},[_c('v-icon',{attrs:{"color":hover ? 'white' : 'secondary',"size":"60"}},[_vm._v("mdi-domain")])],1)])]}}],null,false,2839495987)}),_c('div',{staticClass:"text-center mt-2"},[_c('span',{staticClass:"secondary-font font-weight-medium"},[_vm._v(_vm._s(_vm.$i18n.t("menu.service")))])])],1):_vm._e(),(_vm.$permission.$can('rate-plan.get', 'user'))?_c('v-card',{staticClass:"mr-5 mb-12",attrs:{"to":{ name: 'rate-plan' },"flat":"","color":"rgba(255,255,255,0)","width":"130","height":"130"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":"1","color":hover ? 'secondary' : 'white',"width":"130","height":"130"}},[_c('div',{staticClass:"d-flex justify-center align-center fill-height"},[_c('v-icon',{attrs:{"color":hover ? 'white' : 'secondary',"size":"60"}},[_vm._v("mdi-credit-card-settings-outline")])],1)])]}}],null,false,3506989314)}),_c('div',{staticClass:"text-center mt-2"},[_c('span',{staticClass:"secondary-font font-weight-medium"},[_vm._v(_vm._s(_vm.$i18n.t("menu.rate-plan")))])])],1):_vm._e(),(_vm.$permission.$can('model-field.list', 'user'))?_c('v-card',{staticClass:"mr-5 mb-12",attrs:{"to":{ name: 'model-data' },"flat":"","color":"rgba(255,255,255,0)","width":"130","height":"130"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":"1","color":hover ? 'secondary' : 'white',"width":"130","height":"130"}},[_c('div',{staticClass:"d-flex justify-center align-center fill-height"},[_c('v-icon',{attrs:{"color":hover ? 'white' : 'secondary',"size":"60"}},[_vm._v("mdi-database-outline")])],1)])]}}],null,false,2249769243)}),_c('div',{staticClass:"text-center mt-2"},[_c('span',{staticClass:"secondary-font font-weight-medium"},[_vm._v(_vm._s(_vm.$i18n.t("menu.form_model_data")))])])],1):_vm._e(),(_vm.$permission.$can('user.get', 'user'))?_c('v-card',{staticClass:"mr-5 mb-12",attrs:{"to":{ name: 'user' },"flat":"","color":"rgba(255,255,255,0)","width":"130","height":"130"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":"1","color":hover ? 'secondary' : 'white',"width":"130","height":"130"}},[_c('div',{staticClass:"d-flex justify-center align-center fill-height"},[_c('v-icon',{attrs:{"color":hover ? 'white' : 'secondary',"size":"60"}},[_vm._v("mdi-account-multiple-outline")])],1)])]}}],null,false,3345779810)}),_c('div',{staticClass:"text-center mt-2"},[_c('span',{staticClass:"secondary-font font-weight-medium"},[_vm._v(_vm._s(_vm.$i18n.t("menu.user")))])])],1):_vm._e(),(_vm.$permission.$can('permission.get', 'user'))?_c('v-card',{staticClass:"mr-5 mb-12",attrs:{"to":{ name: 'permission' },"flat":"","color":"rgba(255,255,255,0)","width":"130","height":"130"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":"1","color":hover ? 'secondary' : 'white',"width":"130","height":"130"}},[_c('div',{staticClass:"d-flex justify-center align-center fill-height"},[_c('v-icon',{attrs:{"color":hover ? 'white' : 'secondary',"size":"60"}},[_vm._v("mdi-security")])],1)])]}}],null,false,1481299877)}),_c('div',{staticClass:"text-center mt-2"},[_c('span',{staticClass:"secondary-font font-weight-medium"},[_vm._v(_vm._s(_vm.$i18n.t("menu.permission")))])])],1):_vm._e(),_c('v-card',{staticClass:"mr-5 mb-12",attrs:{"to":{ name: 'account' },"flat":"","color":"rgba(255,255,255,0)","width":"130","height":"130"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":"1","color":hover ? 'secondary' : 'white',"width":"130","height":"130"}},[_c('div',{staticClass:"d-flex justify-center align-center fill-height"},[_c('v-icon',{attrs:{"color":hover ? 'white' : 'secondary',"size":"60"}},[_vm._v("mdi-account-outline")])],1)])]}}])}),_c('div',{staticClass:"text-center mt-2"},[_c('span',{staticClass:"secondary-font font-weight-medium"},[_vm._v(_vm._s(_vm.$i18n.t("menu.account.title")))])])],1)],1)])],1)],1),_c('footer-bar',{staticClass:"justify-center"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }