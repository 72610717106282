<template>
  <v-app class="app">
    <app-bar
      hide-nav-icon
      :options="{ 'clipped-left': true, 'clipped-right': true }"
    ></app-bar>
    <v-main class="pb-0 mb-0">
      <v-container class="fill-height">
        <v-card
          color="rgba(255,255,255,0)"
          flat
          max-width="800"
          class="mx-auto"
        >
          <div class="d-flex flex-wrap flex-row justify-center">
            <v-card
              :to="{ name: 'statistic-message' }"
              v-if="
                $permission.$can('message.statistic', ['user', 'service']) ||
                $permission.$can('ticket.statistic', ['user', 'service'])
              "
              flat
              color="rgba(255,255,255,0)"
              width="130"
              height="130"
              class="mr-5 mb-12"
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  elevation="1"
                  :color="hover ? 'secondary' : 'white'"
                  width="130"
                  height="130"
                >
                  <div class="d-flex justify-center align-center fill-height">
                    <v-icon :color="hover ? 'white' : 'secondary'" size="60"
                      >mdi-chart-box-outline</v-icon
                    >
                  </div>
                </v-card>
              </v-hover>
              <div class="text-center mt-2">
                <span class="secondary-font font-weight-medium">{{
                  $t("menu.statistics.title")
                }}</span>
              </div>
            </v-card>
            <v-card
              :to="{ name: 'chat' }"
              v-if="$permission.$can('conversation.get', ['user', 'service'])"
              flat
              color="rgba(255,255,255,0)"
              width="130"
              height="130"
              class="mr-5 mb-12"
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  elevation="1"
                  :color="hover ? 'secondary' : 'white'"
                  width="130"
                  height="130"
                >
                  <div class="d-flex justify-center align-center fill-height">
                    <v-icon :color="hover ? 'white' : 'secondary'" size="60"
                      >mdi-chat-outline</v-icon
                    >
                  </div>
                </v-card>
              </v-hover>
              <div class="text-center mt-2">
                <span class="secondary-font font-weight-medium">{{
                  $t("menu.conversation")
                }}</span>
              </div>
            </v-card>
            <v-card
              :to="{ name: 'tickets' }"
              flat
              color="rgba(255,255,255,0)"
              width="130"
              height="130"
              class="mr-5 mb-12"
              v-if="$permission.$can('ticket.list', ['user', 'service'])"
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  elevation="1"
                  :color="hover ? 'secondary' : 'white'"
                  width="130"
                  height="130"
                >
                  <div class="d-flex justify-center align-center fill-height">
                    <v-icon :color="hover ? 'white' : 'secondary'" size="60"
                      >mdi-ticket-outline</v-icon
                    >
                  </div>
                </v-card>
              </v-hover>
              <div class="text-center mt-2">
                <span class="secondary-font font-weight-medium">{{
                  $i18n.t("menu.tickets")
                }}</span>
              </div>
            </v-card>
            <v-card
              :to="{ name: 'forms' }"
              v-if="$permission.$can('form.list', ['user', 'service'])"
              flat
              color="rgba(255,255,255,0)"
              width="130"
              height="130"
              class="mr-5 mb-12"
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  elevation="1"
                  :color="hover ? 'secondary' : 'white'"
                  width="130"
                  height="130"
                >
                  <div class="d-flex justify-center align-center fill-height">
                    <v-icon :color="hover ? 'white' : 'secondary'" size="60"
                      >mdi-form-select</v-icon
                    >
                  </div>
                </v-card>
              </v-hover>
              <div class="text-center mt-2">
                <span class="secondary-font font-weight-medium">{{
                  $i18n.t("menu.forms")
                }}</span>
              </div>
            </v-card>
            <v-card
              :to="{ name: 'sla' }"
              v-if="$permission.$can('sla.get', ['user', 'service'])"
              flat
              color="rgba(255,255,255,0)"
              width="130"
              height="130"
              class="mr-5 mb-12"
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  elevation="1"
                  :color="hover ? 'secondary' : 'white'"
                  width="130"
                  height="130"
                >
                  <div class="d-flex justify-center align-center fill-height">
                    <v-icon :color="hover ? 'white' : 'secondary'" size="60"
                      >mdi-handshake-outline</v-icon
                    >
                  </div>
                </v-card>
              </v-hover>
              <div class="text-center mt-2">
                <span class="secondary-font font-weight-medium">{{
                  $i18n.t("menu.sla")
                }}</span>
              </div>
            </v-card>
            <v-card
              :to="{ name: 'provision' }"
              flat
              color="rgba(255,255,255,0)"
              width="130"
              height="130"
              class="mr-5 mb-12"
              v-if="
                $permission.$can('service.provision.get', ['user', 'service'])
              "
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  elevation="1"
                  :color="hover ? 'secondary' : 'white'"
                  width="130"
                  height="130"
                >
                  <div class="d-flex justify-center align-center fill-height">
                    <v-icon :color="hover ? 'white' : 'secondary'" size="60"
                      >mdi-wallet-outline</v-icon
                    >
                  </div>
                </v-card>
              </v-hover>
              <div class="text-center mt-2">
                <span class="secondary-font font-weight-medium">{{
                  $i18n.t("menu.provision")
                }}</span>
              </div>
            </v-card>
            <v-card
              :to="{ name: 'service' }"
              flat
              color="rgba(255,255,255,0)"
              v-if="$permission.$can('service.get', ['user', 'service'])"
              width="130"
              height="130"
              class="mr-5 mb-12"
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  elevation="1"
                  :color="hover ? 'secondary' : 'white'"
                  width="130"
                  height="130"
                >
                  <div class="d-flex justify-center align-center fill-height">
                    <v-icon :color="hover ? 'white' : 'secondary'" size="60"
                      >mdi-domain</v-icon
                    >
                  </div>
                </v-card>
              </v-hover>
              <div class="text-center mt-2">
                <span class="secondary-font font-weight-medium">{{
                  $i18n.t("menu.service")
                }}</span>
              </div>
            </v-card>
            <v-card
              :to="{ name: 'rate-plan' }"
              flat
              color="rgba(255,255,255,0)"
              v-if="$permission.$can('rate-plan.get', 'user')"
              width="130"
              height="130"
              class="mr-5 mb-12"
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  elevation="1"
                  :color="hover ? 'secondary' : 'white'"
                  width="130"
                  height="130"
                >
                  <div class="d-flex justify-center align-center fill-height">
                    <v-icon :color="hover ? 'white' : 'secondary'" size="60"
                      >mdi-credit-card-settings-outline</v-icon
                    >
                  </div>
                </v-card>
              </v-hover>
              <div class="text-center mt-2">
                <span class="secondary-font font-weight-medium">{{
                  $i18n.t("menu.rate-plan")
                }}</span>
              </div>
            </v-card>
            <v-card
              :to="{ name: 'model-data' }"
              flat
              color="rgba(255,255,255,0)"
              v-if="$permission.$can('model-field.list', 'user')"
              width="130"
              height="130"
              class="mr-5 mb-12"
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  elevation="1"
                  :color="hover ? 'secondary' : 'white'"
                  width="130"
                  height="130"
                >
                  <div class="d-flex justify-center align-center fill-height">
                    <v-icon :color="hover ? 'white' : 'secondary'" size="60"
                      >mdi-database-outline</v-icon
                    >
                  </div>
                </v-card>
              </v-hover>
              <div class="text-center mt-2">
                <span class="secondary-font font-weight-medium">{{
                  $i18n.t("menu.form_model_data")
                }}</span>
              </div>
            </v-card>
            <v-card
              :to="{ name: 'user' }"
              flat
              color="rgba(255,255,255,0)"
              v-if="$permission.$can('user.get', 'user')"
              width="130"
              height="130"
              class="mr-5 mb-12"
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  elevation="1"
                  :color="hover ? 'secondary' : 'white'"
                  width="130"
                  height="130"
                >
                  <div class="d-flex justify-center align-center fill-height">
                    <v-icon :color="hover ? 'white' : 'secondary'" size="60"
                      >mdi-account-multiple-outline</v-icon
                    >
                  </div>
                </v-card>
              </v-hover>
              <div class="text-center mt-2">
                <span class="secondary-font font-weight-medium">{{
                  $i18n.t("menu.user")
                }}</span>
              </div>
            </v-card>
            <v-card
              :to="{ name: 'permission' }"
              flat
              color="rgba(255,255,255,0)"
              v-if="$permission.$can('permission.get', 'user')"
              width="130"
              height="130"
              class="mr-5 mb-12"
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  elevation="1"
                  :color="hover ? 'secondary' : 'white'"
                  width="130"
                  height="130"
                >
                  <div class="d-flex justify-center align-center fill-height">
                    <v-icon :color="hover ? 'white' : 'secondary'" size="60"
                      >mdi-security</v-icon
                    >
                  </div>
                </v-card>
              </v-hover>
              <div class="text-center mt-2">
                <span class="secondary-font font-weight-medium">{{
                  $i18n.t("menu.permission")
                }}</span>
              </div>
            </v-card>
            <v-card
              :to="{ name: 'account' }"
              flat
              color="rgba(255,255,255,0)"
              width="130"
              height="130"
              class="mr-5 mb-12"
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  elevation="1"
                  :color="hover ? 'secondary' : 'white'"
                  width="130"
                  height="130"
                >
                  <div class="d-flex justify-center align-center fill-height">
                    <v-icon :color="hover ? 'white' : 'secondary'" size="60"
                      >mdi-account-outline</v-icon
                    >
                  </div>
                </v-card>
              </v-hover>
              <div class="text-center mt-2">
                <span class="secondary-font font-weight-medium">{{
                  $i18n.t("menu.account.title")
                }}</span>
              </div>
            </v-card>
          </div>
        </v-card>
      </v-container>
    </v-main>
    <footer-bar class="justify-center"></footer-bar>
  </v-app>
</template>

<script>
import AppBar from "./../components/layout/app-bar";
import FooterBar from "./../components/layout/footer-bar";
export default {
  name: "home",
  components: {
    FooterBar,
    AppBar,
  },
};
</script>
